// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import '../components/link/style.less'

import ContactForm from '../components/contact-form'
import '../components/contact-form/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import Breadcrumbs from '../components/breadcrumbs'
import '../components/breadcrumbs/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageSchema = {
  title: 'Support',
  slug: '/support',
  abstract: 'Support',
  breadcrumbs: [
    { title: 'Homepage', slug: '' },
    { title: 'Support', slug: '/support' },
  ],
}

const contactFormRef = React.createRef()
const contactFormConf = {
  macroId:
    'AKfycbylCXho7bHnhXdcNSef-LF4qrS4rXqo04LHqdyN_-O4j9BCTbVnBJOMqFq7x4pQJUka',
  includeTitle: true,
  includeExtra: true,
  fields: {
    name: {
      title: 'Name',
      extra: 'Required',
    },
    email: {
      title: 'Email',
      extra: 'Required',
    },
    phone: {
      title: 'Phone',
      extra: 'Required',
    },
    comment: {
      title: 'Comment',
      extra: 'How can we help you today?',
      rules: [
        {
          validator: (_, value) => {
            return Promise.resolve()
          },
        },
      ],
    },
  },
  trace: {
    success:
      'Thank you for your request. We have recieved your message, and will get back to you shortly.',
  },
}

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <StandardPageWrapper
    className="contact-page"
    pageSchema={pageSchema}
    {...props}
  >
    <div className="container">
      <Row
        gutter={[
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
        ]}
      >
        <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
          <Breadcrumbs breadcrumbs={pageSchema.breadcrumbs} />
          <h1>Request Support</h1>
          <div className="as-paragraph" style={{ maxWidth: '30rem' }}>
            <ContactForm
              refs={{ formRef: contactFormRef }}
              conf={{ formConf: contactFormConf }}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <aside
            className="responsive-aside"
            style={{
              borderTop: '2px solid var(--green2)',
              padding: '1.1rem',
              background: 'var(--gray2)',
              marginLeft: '1.1rem',
              borderBottomLeftRadius: '0.55rem',
              borderBottomRightRadius: '0.55rem',
              overflow: 'hidden',
            }}
          >
            <h3>Meet us</h3>
            <p>
              You can also write to us at{' '}
              <Link to="mailto:admin@auranetwork.app">
                admin@auranetwork.app
              </Link>{' '}
              and request to meet us in person. The Aura Project is a dynamic
              project and we keep on evolving and adapting based on inputs we
              recieve from the community.
            </p>
          </aside>
          <aside
            className="responsive-aside"
            style={{
              borderTop: '2px solid var(--green2)',
              padding: '1.1rem',
              background: 'var(--gray2)',
              marginLeft: '1.1rem',
              marginTop: '1.1rem',
              borderBottomLeftRadius: '0.55rem',
              borderBottomRightRadius: '0.55rem',
              overflow: 'hidden',
            }}
          >
            <h3>Other useful links</h3>
            <p>
              <Link className="alt" to="/tutorial/introduction">
                Tutorial&nbsp;<span className="hand">☞</span>
              </Link>
              <br />
              <Link className="alt" to="/report-a-bug">
                Report a bug&nbsp;<span className="hand">☞</span>
              </Link>
              <br />
              <Link className="alt" to="/community-feedback">
                Community feedback&nbsp;<span className="hand">☞</span>
              </Link>
              <br />
              <Link className="alt" to="/give-feedback">
                Give us feedback&nbsp;<span className="hand">☞</span>
              </Link>
            </p>
          </aside>
          <aside
            className="responsive-aside"
            style={{
              borderTop: '2px solid var(--green2)',
              padding: '1.1rem',
              background: 'var(--gray2)',
              marginLeft: '1.1rem',
              marginTop: '1.1rem',
              borderBottomLeftRadius: '0.55rem',
              borderBottomRightRadius: '0.55rem',
              overflow: 'hidden',
            }}
          >
            <h3>Learn with us</h3>
            <p>
              <Link className="alt" to="/apprentice-or-intern">
                Apprentice or intern&nbsp;<span className="hand">☞</span>
              </Link>
              <br />
              <Link className="alt" to="/learn">
                Learn&nbsp;<span className="hand">☞</span>
              </Link>
              <br />
              <Link className="alt" to="https://book.auranetwork.app/en">
                Web book&nbsp;<span className="hand">☞</span>
              </Link>
            </p>
          </aside>
          <aside
            className="responsive-aside"
            style={{
              borderTop: '2px solid var(--green2)',
              padding: '1.1rem',
              background: 'var(--gray2)',
              marginLeft: '1.1rem',
              marginTop: '1.1rem',
              borderBottomLeftRadius: '0.55rem',
              borderBottomRightRadius: '0.55rem',
              overflow: 'hidden',
            }}
          >
            <h3>Support us</h3>
            <p>
              Please consider donating so we can give shape to the Aura Project
              for Auroville and beyond.
            </p>
            <p>
              <Link className="alt" to="/donate">
                Donate page&nbsp;<span className="hand">☞</span>
              </Link>
            </p>
          </aside>
        </Col>
      </Row>
    </div>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
